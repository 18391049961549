<template>
  <div class="v-stack h-stretch gap-3">
    <template v-if="!minimal">
      <a class="heading-title-2">Gear</a>
      <div class="card light mn">
        <div class="content h-stretch">
          <div class="v-stack h-stretch gap-3">
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <div class="pane-horizontal gap-3">
                <div class="text-right">Owned Gear Cost:</div>
                <div class="text-left">{{ format.currency(gearCost) }} kč</div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Gear Expenses:</div>
                <div class="text-left">
                  {{ format.currency(otherGearCost) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Gear Revenue:</div>
                <div class="text-left">
                  {{ format.currency(gearRevenue) }} kč
                </div>
              </div>
              <div class="separator"></div>
              <div class="pane-horizontal gap-3" style="font-weight: bold">
                <div class="text-right">Total:</div>
                <div class="text-left">
                  {{ format.currency(balance) }}
                  kč
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div
      class="v-stack gap-3 h-stretch"
      v-for="category in categories"
      :key="category.name"
    >
      <div class="h-stack h-start gap-3">
        <a
          class="heading-title-2"
          @click="category.expanded = !category.expanded"
          >{{ category.name }}</a
        >

        <div v-if="category.expanded">&#9660;</div>
        <div v-if="!category.expanded">&#9650;</div>
      </div>
      <Table
        v-if="category.expanded && !minimal"
        :items="items.filter((item) => item.categorySub == category.name)"
        :defaultColumn="0"
        :css="'1fr 60px 120px 120px 160px 80px'"
        v-on:rowSelected="$emit('rowSelected', $event)"
        :columns="[
          {
            name: 'Name',
            sort: 'alphabet',
            path: ['name'],
          },
          {
            name: 'Owned',
            sort: 'numeric',
            path: ['gear', 'length'],
          },
          {
            name: 'Cost',
            sort: 'numeric',
            path: ['gearValue'],
            format: 'czk',
            formatType: 'currency',
          },
          {
            name: 'Revenue',
            sort: 'numeric',
            path: ['gearRevenue'],
            format: 'czk',
            formatType: 'currency',
            formatMap: (price) => Math.round(price),
          },
          {
            name: 'Balance',
            sort: 'alphabet',
            formatMap: (item) => `${item.gearRevenue - item.gearValue}`,
            formatType: 'currency',
            format: 'czk',
          },
          {
            name: '%',
            sort: 'alphabet',
            formatMap: (item) =>
              `${
                item.gearValue > 0
                  ? Math.round((item.gearRevenue / item.gearValue) * 100)
                  : 0
              }%`,
          },
        ]"
      ></Table>
      <Table
        v-if="category.expanded && minimal"
        :items="items.filter((item) => item.categorySub == category.name)"
        :defaultColumn="0"
        :css="'1fr 60px'"
        v-on:rowSelected="$emit('rowSelected', $event)"
        :columns="[
          {
            name: 'Name',
            sort: 'alphabet',
            path: ['name'],
          },
          {
            name: 'Owned',
            sort: 'numeric',
            path: ['gear', 'length'],
          },
        ]"
      ></Table>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import constants from "@/constants.js";
import utils from "@/utils.js";

export default {
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      expenses: [],
      categories: constants.pricelistSubCategoriesGear.map((item) => {
        return {
          name: item,
          expanded: true,
        };
      }),
      format: utils.format,
    };
  },
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getPricelistItems", "getProjects", "getExpenses"]),
  },
  computed: {
    gearCost() {
      let sum = 0;
      for (const item of this.items) {
        sum += item.gearValue;
      }
      return sum;
    },
    gearRevenue() {
      let sum = 0;
      for (const item of this.items) {
        sum += item.gearRevenue;
      }
      return sum;
    },
    otherGearCost() {
      let sum = 0;
      for (const expense of this.expenses) {
        sum += expense.amount;
      }
      return sum;
    },
    balance() {
      return this.gearRevenue - (this.gearCost + this.otherGearCost);
    },
  },
  mounted() {
    this.getExpenses()
      .then((expenses) => {
        for (const expense of expenses) {
          if (expense.category == "gear") {
            if (expense.reccuring) {
              const numberOfMonths = moment().diff(
                moment(expense.date),
                "months"
              );

              for (let i = 1; i <= numberOfMonths; i++) {
                const date = moment(expense.date);
                const copy = { ...expense };
                copy.date = date.month(date.month() + i).toISOString();
                copy._id = uuidv4();

                if (moment(date).isAfter(moment())) {
                  break;
                }

                const endDate = moment(copy.reccuringEndDate);
                if (endDate._isValid) {
                  if (moment(copy.date).isSameOrBefore(endDate, "day")) {
                    this.expenses.push(copy);
                  } else {
                    break;
                  }
                } else {
                  this.expenses.push(copy);
                }
              }
            }
            this.expenses.push(expense);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.items = [];
    this.getPricelistItems()
      .then((items) => {
        for (const item of items) {
          if (
            item.categoryMain == "TECHNIKA" &&
            item.categorySub != "Doprava" &&
            item.inHouse
          ) {
            item.gearValue = 0;
            item.gearRevenue = 0;

            for (const gear of item.gear) {
              item.gearValue += gear.value;
            }

            this.items.push(item);
          }
        }

        this.getProjects()
          .then((projects) => {
            this.gearInBudget = [];

            for (const project of projects) {
              for (const pricelist of project.selectedPricelists) {
                for (const item of pricelist.items) {
                  for (const gearItem of this.items) {
                    if (item.item._id == gearItem._id) {
                      gearItem.gearRevenue +=
                        item.count *
                        item.price *
                        ((100 - pricelist.discountPercent) / 100);
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
a.heading-title-2 {
  align-self: center;
  padding: 0px;
  user-select: none;
}

.heading-title-2:hover {
  text-decoration: underline;
}
</style>
