<template>
  <div class="v-stack h-stretch">
    <div class="card light">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left">Date of Purchase:</label>
          <InputDatePicker v-model="dateOfPurchase"></InputDatePicker>
          <label class="text-left">Store:</label>
          <input type="text" v-model="storeDescription" />
          <label class="text-left">Serial Number:</label>
          <input type="text" v-model="serialNumber" />
          <label class="text-left">Cost:</label>
          <input type="number" v-model="value" />
          <label class="text-left">Description:</label>
          <input type="text" v-model="description" />
          <div class="h-stack h-end">
            <button @click.prevent="submit()" class="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const moment = require("moment");
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  data() {
    return {
      dateOfPurchase: moment().toISOString(),
      storeDescription: "",
      serialNumber: "",
      value: 0,
      description: "",
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions(["addGearItem"]),
    submit() {
      this.addGearItem({
        pricelistItem: this.id,
        dateOfPurchase: this.dateOfPurchase,
        storeDescription: this.storeDescription,
        serialNumber: this.serialNumber,
        value: this.value,
        description: this.description,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
