<template>
  <div class="v-stack gap-3 h-stretch">
    <div class="overlay" ref="overlay">
      <div class="card hide-y v-strech h-stretch pa-5">
        <div class="v-stack scroll-y v-start h-stretch">
          <TableGear
            :minimal="true"
            v-on:rowSelected="selectItem($event)"
          ></TableGear>
        </div>
      </div>
    </div>

    <div class="h-stack gap-3">
      <button class="add" @click="generic = true">Generic</button>
      <button class="add" @click="generic = false">Pricelist</button>
    </div>
    <div class="h-stack gap-3 h-stretch pa-4" v-if="!generic">
      <div class="pane-left gap-3">
        <div style="align-self: center">Item:</div>
        <div class="pane-right gap-3">
          <input
            v-model="pricelistItem.name"
            type="text"
            disabled
            placeholder="Click the button to select an item"
          />
          <button class="edit" @click="showOverlay">Select</button>
        </div>
      </div>
    </div>
    <AddEditExpense v-if="generic"></AddEditExpense>
    <AddGearItem
      v-if="!generic && pricelistItem"
      :id="pricelistItem ? pricelistItem._id : null"
    ></AddGearItem>
  </div>
</template>

<script>
import AddEditExpense from "@/views/finance/AddEditExpense.vue";
import AddGearItem from "@/views/pricelist/AddGearItem.vue";
import TableGear from "@/components/TableGear.vue";

export default {
  components: {
    AddEditExpense,
    AddGearItem,
    TableGear,
  },
  data() {
    return {
      generic: true,
      pricelistItem: "",
    };
  },
  methods: {
    showOverlay() {
      this.$refs.overlay.classList.add("show");
    },
    selectItem(item) {
      this.$refs.overlay.classList.remove("show");
      if (item) {
        this.pricelistItem = item;
      }
    },
  },
};
</script>

<style scoped>
.overlay .card {
  width: 80vw;
  height: 80vh;
}
</style>
