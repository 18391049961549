var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[(!_vm.minimal)?[_c('a',{staticClass:"heading-title-2"},[_vm._v("Gear")]),_c('div',{staticClass:"card light mn"},[_c('div',{staticClass:"content h-stretch"},[_c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('div',{staticClass:"v-stack h-stretch v-start gap-3 card py-5"},[_c('div',{staticClass:"pane-horizontal gap-3"},[_c('div',{staticClass:"text-right"},[_vm._v("Owned Gear Cost:")]),_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.format.currency(_vm.gearCost))+" kč")])]),_c('div',{staticClass:"pane-horizontal gap-3"},[_c('div',{staticClass:"text-right"},[_vm._v("Gear Expenses:")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.format.currency(_vm.otherGearCost))+" kč ")])]),_c('div',{staticClass:"pane-horizontal gap-3"},[_c('div',{staticClass:"text-right"},[_vm._v("Gear Revenue:")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.format.currency(_vm.gearRevenue))+" kč ")])]),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"pane-horizontal gap-3",staticStyle:{"font-weight":"bold"}},[_c('div',{staticClass:"text-right"},[_vm._v("Total:")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.format.currency(_vm.balance))+" kč ")])])])])])])]:_vm._e(),_vm._l((_vm.categories),function(category){return _c('div',{key:category.name,staticClass:"v-stack gap-3 h-stretch"},[_c('div',{staticClass:"h-stack h-start gap-3"},[_c('a',{staticClass:"heading-title-2",on:{"click":function($event){category.expanded = !category.expanded}}},[_vm._v(_vm._s(category.name))]),(category.expanded)?_c('div',[_vm._v("▼")]):_vm._e(),(!category.expanded)?_c('div',[_vm._v("▲")]):_vm._e()]),(category.expanded && !_vm.minimal)?_c('Table',{attrs:{"items":_vm.items.filter(function (item) { return item.categorySub == category.name; }),"defaultColumn":0,"css":'1fr 60px 120px 120px 160px 80px',"columns":[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Owned',
          sort: 'numeric',
          path: ['gear', 'length'],
        },
        {
          name: 'Cost',
          sort: 'numeric',
          path: ['gearValue'],
          format: 'czk',
          formatType: 'currency',
        },
        {
          name: 'Revenue',
          sort: 'numeric',
          path: ['gearRevenue'],
          format: 'czk',
          formatType: 'currency',
          formatMap: function (price) { return Math.round(price); },
        },
        {
          name: 'Balance',
          sort: 'alphabet',
          formatMap: function (item) { return ("" + (item.gearRevenue - item.gearValue)); },
          formatType: 'currency',
          format: 'czk',
        },
        {
          name: '%',
          sort: 'alphabet',
          formatMap: function (item) { return ((item.gearValue > 0
                ? Math.round((item.gearRevenue / item.gearValue) * 100)
                : 0) + "%"); },
        } ]},on:{"rowSelected":function($event){return _vm.$emit('rowSelected', $event)}}}):_vm._e(),(category.expanded && _vm.minimal)?_c('Table',{attrs:{"items":_vm.items.filter(function (item) { return item.categorySub == category.name; }),"defaultColumn":0,"css":'1fr 60px',"columns":[
        {
          name: 'Name',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Owned',
          sort: 'numeric',
          path: ['gear', 'length'],
        } ]},on:{"rowSelected":function($event){return _vm.$emit('rowSelected', $event)}}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }